import React from 'react';
import classnames from 'classnames';
import { shape, string, bool, object, number, arrayOf } from 'prop-types';
import { Image } from 'nordic/image';
import { MoneyAmountCombo } from '@andes/money-amount';
import { Card as AndesCard } from '@andes/card';
import { Button } from '@andes/button';

import withTracker from '../../with-tracker';
import withTrackerOnPrint from '../../with-tracker-on-print';

import StyledLabelFormated from '../../styled-label/styled-label-formated';
import modelMoneyAmountCombo from '../../dynamic-access/utils/model-money-amount-combo';
import { LAZYLOAD_OFF, ALL_ITEMS_IMAGES } from '../../../../../utils/constants';

const namespace = 'repurchase-widget';

const Widget = ({ card, type }) => {
  const isMobile = type === 'mobile';
  const {
    header,
    picture,
    name,
    permalink,
    price = {},
    shipping = {},
    action,
    tag,
    attributes,
    items_images,
    additional_items,
  } = card;
  const { original_price, current_value, discount } = price;
  const { accessibility_text: accessibilityText } = shipping;
  const hasDescriptionComponents = action || discount || (shipping && shipping.text);

  const itemsImagesLength = items_images
  && items_images.length <= ALL_ITEMS_IMAGES ? items_images.length : ALL_ITEMS_IMAGES;

  return (
    <AndesCard
      className={classnames(
        'repurchase-widget-card',
        namespace,
      )}
    >
      {header && (
        <div className={`${namespace}-header`}>
          <h2 className={`${namespace}__title`}>{header.text}</h2>
        </div>
      )}
      {items_images && itemsImagesLength !== 3 && (
        <div className={`${namespace}__items-images ${namespace}__items-images--${itemsImagesLength}`} aria-hidden="true">
          {items_images.slice(0, 4).map((key, i) => (i !== ALL_ITEMS_IMAGES - 1
            ? (
              <div className={`${namespace}__items-images-container ${namespace}__items-images-container-${itemsImagesLength}`} key={key.picture.id}>
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  width="100%"
                  height="100%"
                  lazyload={LAZYLOAD_OFF}
                  preload
                />
              </div>
            ) : (
              <div
                key={key.picture.id}
                className={`${namespace}__items-images--last-image ${additional_items
                  ? `${namespace}__items-images--additional_items` : ''}`}
              >
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  width="100%"
                  height="100%"
                  lazyload={LAZYLOAD_OFF}
                  preload
                />
                {additional_items > 0 && (
                  <span className={`${namespace}__items-images__label`}>
                    +{additional_items}
                  </span>
                )}
              </div>)))}
        </div>
      )}
      {items_images && itemsImagesLength === 3 && (
        <div className={`${namespace}__${itemsImagesLength}-items-container`} aria-hidden="true">
          <div
            key={items_images[0].picture.id}
            className={`${namespace}__items-images-container ${namespace}__${itemsImagesLength}-items-container-left`}
          >
            <Image
              src={items_images[0].picture.src}
              alt={items_images[0].picture.alt}
              width="100%"
              height="100%"
              lazyload={LAZYLOAD_OFF}
              preload
            />
          </div>
          <div className={`${namespace}__${itemsImagesLength}-items-container-right`}>
            {items_images.slice(1, 3).map((key) => (
              <div key={key.picture.id} className={`${namespace}__${itemsImagesLength}-items-container-right-item`}>
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  width="100%"
                  height="100%"
                  lazyload={LAZYLOAD_OFF}
                  preload
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!items_images && (
        <div className={`${namespace}__one-item-container`}>
          {picture && (
            <div
              className={classnames(`${namespace}__image`, {
                [`${namespace}__image--${picture.format}`]: picture.format })}
              aria-hidden="true"
            >
              <Image
                src={picture.src}
                width="100%"
                height="100%"
                alt={name?.text}
                lazyload={LAZYLOAD_OFF}
                preload
              />
            </div>
          )}
          <div
            className={classnames(`${namespace}__item-description`, {
              [`${namespace}__item-description--with-actions`]: action,
              [`${namespace}__item-description--with-discount`]: discount,
            })}
          >
            {name && (<p className={`${namespace}__item-title`}>{name.text}</p>)}
            {current_value && (
              <MoneyAmountCombo
                previousValue={modelMoneyAmountCombo(original_price, (isMobile ? 12 : 16))}
                currentValue={modelMoneyAmountCombo(current_value, (isMobile ? 20 : 32))}
                discount={discount && { ...discount, size: (isMobile ? 12 : 18) }}
                className={`${namespace}__price`}
              />
            )}
            {shipping && (
              <div className={`${namespace}__container-shipping-free`}>
                <StyledLabelFormated
                  as="span"
                  dataModel={{ ...shipping, accessibilityText }}
                >
                  {shipping.text && shipping.subText && shipping?.styles?.font_size && (
                    <span className={`${namespace}__subtext font-size--${shipping.styles.font_size}`}>
                      {shipping.subText}
                    </span>
                  )}
                </StyledLabelFormated>
              </div>
            )}
            {!hasDescriptionComponents && (
              <>
                {tag ? (
                  <div className={`${namespace}__container-tag`}>
                    <StyledLabelFormated
                      as="span"
                      dataModal={tag}
                    />
                  </div>
                ) : attributes && <span className={`${namespace}__item-attributes`}>{attributes.text}</span>}
              </>
            )}
          </div>
        </div>
      )}

      {action && isMobile && (
        <Button
          hierarchy="loud"
          size="large"
          href={action.link}
          className={`${namespace}__action`}
          srLabel={action.label?.accessibility_text}
        >
          {action.label?.text}
        </Button>
      )}
      {!isMobile && header && (
        <div className={`${namespace}__subtitle`}>
          <a className={`${namespace}__subtitle-link`} href={permalink} aria-label={header.accessibility_text}>{header.sub_text}</a>
        </div>
      )}
    </AndesCard>
  );
};

Widget.propTypes = {
  card: shape({
    permalink: string,
    header: shape({
      text: string,
      subText: string,
    }),
    name: shape({
      text: string,
    }),
    picture: shape({
      src: string,
    }),
    lazyLoad: bool,
    shipping: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    tag: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    attributes: shape({
      text: string,
    }),
    price: shape({
      original_price: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
      }),
      current_value: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
        suffix: string,
      }),
      discount: shape({
        value: number,
      }),
    }),
    accessibility_text: string,
    items_images: arrayOf(shape({
      picture: shape({
        src: string,
        alt: string,
        id: string,
      }),
    })),
    action: shape({
      label: shape({
        color: string,
        font_family: string,
        font_size: string,
        bg_color: string,
        text: string,
      }),
      target: string,
    }),
  }),
  additional_items: string,
  type: string,
};

export default withTracker(withTrackerOnPrint(Widget));
