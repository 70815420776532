import React from 'react';
import { string, object } from 'prop-types';
import Widget from './components/widget';

const RepurchaseWidget = ({ widgetProps, type }) => {
  const { content, track, reco_track } = widgetProps;
  return (
    <Widget
      card={content}
      reco_track={reco_track}
      track={track}
      type={type}
    />
  );
};

RepurchaseWidget.propTypes = {
  widgetProps: object,
  type: string,
};

export default RepurchaseWidget;
